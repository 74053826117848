import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Button, Section, Text, Title } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import imgS1 from "../assets/image/novacair-cover.png";
import { device } from "../utils";
import List from "../components/Core/List";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag text-center">MED-TECH</Text>
                  <Title className="my-4 text-center">
                    NovaCair
                    <br />
                    Preoperative Screening Software
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    <br />
                    NovaCair is a user-friendly digital preoperative screening tool. In the core this project is a questionnaire that
                    can be filled out by the patient at home. The answers are then sent to the our platform where the answers are
                    analyzed by a doctor. The doctor can then decide whether the patient is fit for surgery or not and pay attention during surgery.
                    <br />
                    <br />
                    The project was built in a way that it can be used by multiple hospitals and the tool can be opened from within the
                    hospital software. The tool is not only a stand-alone solution but was also connected to the EPD, meaning that
                    whenever the patient has filled in the questionnaire, the status within the EPD is updated.
                    This allowed for a fast and smooth workflow, thus saving time and money.
                    <br />
                    <br />
                    The questionnaire is fully customizable, rules can be set and the questionnaire such that a patient gets the right questions based on its answers.
                    My role here was Software and DevOps Engineer. I was involved building and maintaining features on the frontend, backend and the server side.
                    <br/>
                    <br/>
                    Here I've got unique insight into building software for Hospitals. The fact that mistakes now could lead to very undesirable results made me a lot more careful and
                    ultimately a better developer.
                    <br/>
                    <br/>
                    The beauty of this project is that it is partly
                    a <strong>Web Tool</strong> that can be opened from within the hospital software. Therefore, allowing quick release cycles and
                    fewer dependencies on machines that reside on premise.
                    <br />
                    <br />

                    <List>
                      <li>I was responsible for massive overhauls on the system, refactoring code such that it was more future-proof and more flexible.</li>
                      <li>Added tons of features to the online web portal. Utilizing and deepening my skills on the frontend, backend and the server side (Linux).</li>
                    </List>
                  </Text>
                </Box>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text css={`font-weight: 800`} variant="h4">
                    Experiences gained here:
                  </Text>

                  <List>
                    <li>Vue2 / Vue3</li>
                    <li>Laravel v9</li>
                    <li>Flutter</li>
                    <li>Deepening knowledge of running on Amazon Web Services.</li>
                    <li>Deepening Knowledge of PHP8</li>
                    <li>How hospitals operate and take decisions</li>
                    <li>Build EPD connections</li>
                    {/*<li>Setting up DataDog</li>*/}

                  </List>

                  <Text variant="h5">
                    Any further questions? Feel free to contact me!
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  NovaCair
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Active Until</Text>
                <Title variant="cardBig" className="mt-3">
                  Jan, 2023
                </Title>
              </Col>
              <Col lg="4" className="d-flex justify-content-lg-end">
                <a target="_blank" href="https://novacair.com">
                  <Button arrowRight>NovaCair.com</Button>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5 pb-0">
          <Container>
            <Row>
              <Col xs="12" className="mb-5">
                <img src={imgS1} alt="" className="img-fluid w-100" />
              </Col>
              {/*<Col xs="12" className="mb-5">*/}
              {/*  <img src={imgS2} alt="" className="img-fluid w-100" />*/}
              {/*</Col>*/}
              {/*<Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">*/}
              {/*  <img src={imgS3} alt="" className="img-fluid w-100" />*/}
              {/*</Col>*/}
              {/*<Col lg="6" className="mb-5 pl-lg-4">*/}
              {/*  <img src={imgS4} alt="" className="img-fluid w-100" />*/}
              {/*</Col>*/}
            </Row>
          </Container>
        </Section>

        <Section bg="dark" className="pt-4">
          <Container>
            {/*<Row>*/}
            {/*  <Col xs="12" className="mb-5">*/}
            {/*    <img src={imgS5} alt="" className="img-fluid w-100" />*/}
            {/*  </Col>*/}
            {/*  <Col xs="12" className="mb-5">*/}
            {/*    <img src={imgS6} alt="" className="img-fluid w-100" />*/}
            {/*  </Col>*/}
            {/*</Row>*/}

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="tjapp">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  TJAPP
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
